<template>
  <div class="content d-flex flex-column">
    <header-page />
    <functionalities />
  </div>
</template>

<script>
import HeaderPage from '@/components/soil/home/HeaderPage.vue'
import Functionalities from '@/components/soil/home/Functionalities.vue'

export default {
  name: 'SoilHome',

  components: {
    HeaderPage,
    Functionalities,
  },
}
</script>
