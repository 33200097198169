<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent d-print-none">
        <h5 class="card-title">
          <router-link :to="`/soil/home`" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis`" title="Voltar">
            Análise de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis/${batchId}`" title="Voltar">
            Detalhes da Análise
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Laudo
        </h5>
      </div>
      <div class="d-flex d-print-none">
        <div class="col">
          <div class="form-group mt-2">
            <label>Selecione um talhão:</label>
            <select class="form-control" v-model="selectedArea">
              <option value="" title="Selecione um talhão">
                Selecione um talhão
              </option>
              <option
                :value="area.areaId"
                v-for="(area, index) in areas"
                :key="index"
              >
                {{ area.clientName }} - {{ area.farmName }} -
                {{ area.areaName }} - (Número de Amostras:
                {{ area.numberSamples }})
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="reportData">
        <report-page :dataReport="reportData" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import ReportPage from '@/components/soil/analysis/ReportPage.vue'

export default {
  name: 'SoilAnalysisReport',

  components: {
    LoadingData,
    ReportPage,
  },

  data() {
    return {
      batchId: this.$route.params.batchId,
      loadData: true,
      selectedArea: '',
      areas: [],
      reportData: null,
    }
  },

  watch: {
    selectedArea: {
      handler(newVal, oldVal) {
        this.reportData = null
        if (newVal !== '') {
          this.getReportData()
        }
      },
    },
  },

  methods: {
    async getAreasResults() {
      try {
        this.areas = await this.$store.dispatch(
          'getAreasResultsByBatch',
          this.batchId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getReportData() {
      try {
        this.reportData = await this.$store.dispatch(
          'getReportDataByBatchAndArea',
          {
            batchId: this.batchId,
            areaId: this.selectedArea,
          },
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getAreasResults()
  },
}
</script>
