<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nº</th>
        <th class="text-center">Número de Amostras</th>
        <th class="text-center">Status</th>
        <th>Criado por</th>
        <th>Atualizado por</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="batch in batchs" :key="batch.id">
        <td>{{ batch.id }}</td>
        <td class="text-center">{{ batch.numberOfSamples }}</td>
        <td class="text-center">
          <span :class="'badge bg-' + batch.statusClass">
            {{ batch.statusName }}
          </span>
        </td>
        <td>
          {{ createdUpdatedUser(batch.createdBy, batch.createdAt) }}
        </td>
        <td>
          {{ createdUpdatedUser(batch.updatedBy, batch.updatedAt) }}
        </td>
        <td class="text-center">
          <router-link
            :to="`/soil/${segment}/${batch.id}`"
            class="btn btn-outline-primary btn-icon rounded-pill"
            title="Detalhar"
          >
            <i class="far fa-file-alt" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListBatchs',

  props: {
    batchs: {
      type: Array,
      required: true,
    },
    segment: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListBatchs',
        columnDefs: [
          {
            orderable: false,
            width: 100,
            targets: [5],
          },
        ],
      },
      createdUpdatedUser,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        order: [0, 'desc'],
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
