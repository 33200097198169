<template>
  <div>
    <loading-data v-if="loadData" />
    <div v-else class="row">
      <div v-if="atomicAbsorption" class="col-12 col-md-4 mb-2">
        <div
          class="btn btn-float mr-2 btn-primary-100 text-primary w-100"
          title="Download"
          @click="downloadTemplate(atomicAbsorption)"
        >
          <i class="fas fa-atom fa-2x mr-2 mb-2" />
          Planilha para Preenchimento da Absorção Atômica
        </div>
      </div>
      <div v-if="spectrophotometry" class="col-12 col-md-4 mb-2">
        <div
          class="btn btn-float mr-2 btn-purple-100 text-purple w-100"
          title="Download"
          @click="downloadTemplate(spectrophotometry)"
        >
          <i class="fas fa-glasses fa-2x mr-2 mb-2" />
          Planilha para Preenchimento da Espectrofotometria
        </div>
      </div>
      <div v-if="titration" class="col-12 col-md-4 mb-2">
        <div
          class="btn btn-float mr-2 btn-teal-100 text-teal w-100"
          title="Download"
          @click="downloadTemplate(titration)"
        >
          <i class="fas fa-vial fa-2x mr-2 mb-2" />
          Planilha para Preenchimento da Titulometria
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'DownloadTemplate',

  components: {
    LoadingData,
  },

  props: {
    batchId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      atomicAbsorption: null,
      spectrophotometry: null,
      titration: null,
    }
  },

  methods: {
    async loadAtomicAbsorptionTemplate() {
      try {
        const atomicAbsorption = await this.$store.dispatch(
          'getGenericTemplate',
          {
            batchId: this.batchId,
            analysisId: 1,
          },
        )

        this.atomicAbsorption = {
          fileName: `L_${this.batchId}_AA`,
          sheet: 'Absorção Atômica',
          fieldsName: Object.keys(atomicAbsorption.fields),
          fieldsValue: Object.values(atomicAbsorption.fields),
          content: atomicAbsorption.lines,
        }
        this.verifyAllContentIsLoaded()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async loadSpectrophotometryTemplate() {
      try {
        const spectrophotometry = await this.$store.dispatch(
          'getGenericTemplate',
          {
            batchId: this.batchId,
            analysisId: 2,
          },
        )

        this.spectrophotometry = {
          fileName: `L_${this.batchId}_Espectro`,
          sheet: 'Espectrofotometria',
          fieldsName: Object.keys(spectrophotometry.fields),
          fieldsValue: Object.values(spectrophotometry.fields),
          content: spectrophotometry.lines,
        }
        this.verifyAllContentIsLoaded()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async loadTitrationTemplate() {
      try {
        const titration = await this.$store.dispatch('getGenericTemplate', {
          batchId: this.batchId,
          analysisId: 3,
        })

        this.titration = {
          fileName: `L_${this.batchId}_Tit`,
          sheet: 'Titulometria',
          fieldsName: Object.keys(titration.fields),
          fieldsValue: Object.values(titration.fields),
          content: titration.lines,
        }
        this.verifyAllContentIsLoaded()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    verifyAllContentIsLoaded() {
      if (
        this.atomicAbsorption !== null &&
        this.spectrophotometry !== null &&
        this.titration !== null
      ) {
        this.loadData = false
      }
    },

    downloadTemplate(model) {
      const settings = {
        fileName: model.fileName,
      }

      const template = [
        {
          sheet: model.sheet,
          columns: [],
          content: [],
        },
      ]

      model.fieldsName.forEach((field, index) => {
        template[0].columns.push({
          label: field,
          value: model.fieldsValue[index],
        })
      })

      template[0].content = model.content

      xlsx(template, settings)
    },
  },

  mounted() {
    this.loadAtomicAbsorptionTemplate()
    this.loadSpectrophotometryTemplate()
    this.loadTitrationTemplate()
  },
}
</script>
