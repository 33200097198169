<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nº</th>
        <th>Cliente</th>
        <th>Fazenda</th>
        <th>Talhão</th>
        <th class="text-center">Número de Amostras</th>
        <th class="text-center">Status</th>
        <th>Criado por</th>
        <th>Atualizado por</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="shipment in shipments" :key="shipment.id">
        <td>{{ shipment.id }}</td>
        <td>{{ shipment.clientName }}</td>
        <td>{{ shipment.farmName }}</td>
        <td>{{ shipment.areaName }}</td>
        <td class="text-center">{{ shipment.numberOfSamples }}</td>
        <td class="text-center">
          <span :class="'badge bg-' + shipment.statusClass">
            {{ shipment.statusName }}
          </span>
        </td>
        <td>
          {{ createdUpdatedUser(shipment.createdBy, shipment.createdAt) }}
        </td>
        <td>
          {{ createdUpdatedUser(shipment.updatedBy, shipment.updatedAt) }}
        </td>
        <td class="text-center">
          <router-link
            :to="'/soil/shipments/' + shipment.id"
            class="btn btn-outline-primary btn-icon rounded-pill"
            title="Detalhar"
          >
            <i class="far fa-file-alt" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListShipments',

  props: {
    shipments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListShipments',
        columnDefs: [
          {
            orderable: false,
            width: 100,
            targets: [8],
          },
        ],
      },
      createdUpdatedUser,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        order: [0, 'desc'],
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
