<template>
  <div class="content">
    <create-edit-type-of-analysis-modal
      v-if="showCreateEditTypeOfAnalysisModal"
      :typeOfAnalysisId="typeOfAnalysisIdSelected"
      @closeModal="closeCreateEditTypeOfAnalysisModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar"> Módulo de Solos </router-link>
          <i class="fas fa-angle-right ml-2" />
          Tipos de Análises
        </h5>
        <div v-if="getModuleUserByCode('3.02.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Medição de Profundidade"
            @click="createEditTypeOfAnalysis()"
          >
            Novo Tipo de Análise
          </button>
        </div>
      </div>
      <list-types-of-analysis
        :typesOfAnalysis="typesOfAnalysis"
        @createEditTypeOfAnalysis="createEditTypeOfAnalysis"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditTypeOfAnalysisModal from '@/components/soil/typeofanalysis/CreateEditTypeOfAnalysisModal.vue'
import ListTypesOfAnalysis from '@/components/soil/typeofanalysis/ListTypesOfAnalysis.vue'

export default {
  name: 'SoilTypeOfAnalysis',

  components: {
    LoadingData,
    CreateEditTypeOfAnalysisModal,
    ListTypesOfAnalysis,
  },

  data() {
    return {
      loadData: true,
      showCreateEditTypeOfAnalysisModal: false,
      typesOfAnalysis: null,
      typeOfAnalysisIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getTypesOfAnalysis() {
      try {
        this.typesOfAnalysis = await this.$store.dispatch('getTypesOfAnalysis')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditTypeOfAnalysis(typeOfAnalysisId) {
      this.typeOfAnalysisIdSelected = typeOfAnalysisId || null
      this.showCreateEditTypeOfAnalysisModal = true
    },

    closeCreateEditTypeOfAnalysisModal(loadListTypesOfAnalysis) {
      if (loadListTypesOfAnalysis) {
        this.loadData = true
        this.getTypesOfAnalysis()
      }
      this.showCreateEditTypeOfAnalysisModal = false
    },
  },

  mounted() {
    this.getTypesOfAnalysis()
  },
}
</script>
