<template>
  <div
    id="modalCancelBatch"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cancelar Lote</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="cancelBatch">
          <div class="modal-body">
            <p>Tem certeza que deseja cancelar este lote?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link text-danger"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Confirmar Cancelamento do Lote"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Confirmar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CancelBatchModal',

  props: {
    batchId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      disableButton: false,
    }
  },

  methods: {
    async cancelBatch() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('cancelBatch', this.batchId)
        this.disableButton = false
        window.$('#modalCancelBatch').modal('hide')
        this.$emit('closeModal', true)
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    window.$('#modalCancelBatch').modal()
  },
}
</script>
