<template>
  <div class="row">
    <div v-if="analysisPendent" class="col-12 col-md-4 mb-2">
      <button
        disabled
        class="btn btn-float btn-indigo text-white w-100"
        title="Processar Dados"
      >
        <i class="fas fa-calculator fa-2x mb-2" />
        Processar Dados
      </button>
      <p class="text-center text-muted mt-2 mb-0">
        Antes de processar os dados, preencha as análises
      </p>
    </div>
    <div v-else class="col-12 col-md-4 mb-2">
      <button
        class="btn btn-float btn-indigo text-white w-100"
        title="Processamento"
        @click="processData()"
      >
        <i class="fas fa-calculator fa-2x mb-2" />
        {{ results.length > 0 ? 'Processar Novamente' : 'Processar Dados' }}
      </button>
      <p v-if="results.length > 0" class="text-center text-muted mt-2 mb-0">
        Os dados atuais serão excluídos
      </p>
    </div>
    <div class="col-12 col-md-4 mb-2">
      <router-link
        :to="`/soil/analysis/${batchId}/results`"
        class="btn btn-float btn-warning text-white w-100"
        :class="{ disabled: results.length === 0 }"
        :disabled="results.length === 0"
        title="Resultados"
      >
        <i class="fas fa-table fa-2x mb-2" />
        {{ results ? 'Acessar Dados' : 'Aguardando Processamento' }}
      </router-link>
      <p v-if="analysisPendent" class="text-center text-muted mt-2 mb-0">
        Para acessar os dados, realize o processamento
      </p>
    </div>
    <div class="col-12 col-md-4 mb-2">
      <router-link
        :to="`/soil/analysis/${batchId}/reports`"
        class="btn btn-float btn-success text-white w-100"
        :class="{ disabled: results.length === 0 }"
        :disabled="results.length === 0"
        title="Laudo"
      >
        <i class="fas fa-file-contract fa-2x mb-2" />
        {{ results ? 'Acessar Laudo' : 'Aguardando Processamento' }}
      </router-link>
      <p v-if="analysisPendent" class="text-center text-muted mt-2 mb-0">
        Para acessar os dados, realize o processamento
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessDataByCards',

  props: {
    batchId: {
      type: String,
      required: true,
    },
    analysis: {
      type: Array,
      required: true,
    },
    analysisPendent: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      results: [],
    }
  },

  methods: {
    async processData() {
      try {
        const response = await this.$store.dispatch(
          'processDataAnalysis',
          this.batchId,
        )
        this.getResults()
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getResults() {
      try {
        this.results = await this.$store.dispatch(
          'getResultsByBatch',
          this.batchId,
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    if (!this.analysisPendent) {
      this.getResults()
    }
  },
}
</script>
