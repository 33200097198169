<template>
  <div
    id="modalDeleteShipment"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Excluir Remessa</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="deleteShipment">
          <div class="modal-body">
            <p>Tem certeza que deseja excluir esta remessa?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link text-danger"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Excluir Remessa"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Excluir</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteShipmentModal',

  props: {
    shipmentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      disableButton: false,
    }
  },

  methods: {
    async deleteShipment() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch(
          'deleteShipment',
          this.shipmentId,
        )
        this.disableButton = false
        window.$('#modalDeleteShipment').modal('hide')
        this.$emit('closeModal', true)
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    window.$('#modalDeleteShipment').modal()
  },
}
</script>
