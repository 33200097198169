<template>
  <div
    id="modalCreateEditDepth"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ textModal }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Nome: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.depth.name.$error }"
                  placeholder="Nome da Profundidade"
                  v-model="$v.depth.name.$model"
                />
                <span v-if="!$v.depth.name.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
                <span v-if="!$v.depth.name.minLength" class="invalid-feedback">
                  O nome deve possuir pelo menos 4 caracteres
                </span>
              </div>
            </div>
            <div v-if="depthId" class="form-row">
              <div class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="depth.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="depth.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>
                {{ textModal }}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditDepthModal',

  props: {
    depthId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      depth: {
        name: '',
        status: '',
      },
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.depthId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getDepth() {
      try {
        this.depth = await this.$store.dispatch('getDepth', this.depthId)
        window.$('#modalCreateEditDepth').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalCreateEditDepth').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.depthId) {
            response = await this.$store.dispatch('updateDepth', this.depth)
          } else {
            response = await this.$store.dispatch('saveDepth', this.depth)
          }
          this.disableButton = false
          window.$('#modalCreateEditDepth').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    depth: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
  },

  mounted() {
    if (this.depthId) {
      this.getDepth()
    } else {
      window.$('#modalCreateEditDepth').modal()
    }
  },
}
</script>
