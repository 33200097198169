<template>
  <div class="printable-area border" v-if="area">
    <div class="border">
      <div class="row d-flex">
        <div class="col-3 d-flex align-items-center justify-content-center">
          <img src="@/assets/img/logo.png" class="logo-company" alt="" />
        </div>
        <div class="col-9 text-center">
          <h5 class="mb-1">ESTAÇÃO TERRAS GERAIS EXPERIMENTAL</h5>
          <p class="mb-1">
            Sítio Campo Limpo, Zona Rural- Lavras- MG- Caixa Postal: 3033- CEP:
            37200-973
          </p>
          <p class="mb-1">
            Fone: (35) 9 9959-0400/ E-mail: contato@terrasgerais.com
          </p>
          <p class="mb-1">www.terrasgerais.com</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="border">
          <h6 class="mb-0 text-center font-weight-black">
            Laudo de Análise Solo
          </h6>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="col border pr-1">
        <p class="mb-0">Razão Social: {{ area.clientName }}</p>
      </div>
      <div class="col border pr-1">
        <p class="mb-0">Data de Entrada: {{ area.shipmentDate }}</p>
      </div>
    </div>
    <div class="d-flex">
      <div class="col border pr-1">
        <p class="mb-0">Propriedade: {{ area.farmName }}</p>
      </div>
      <div class="col border pr-1">
        <p class="mb-0">Telefone: {{ area.clientPhone }}</p>
      </div>
    </div>
    <div class="d-flex">
      <div class="col border pr-1">
        <p class="mb-0">Talhão: {{ area.name }}</p>
      </div>
      <div class="col border pr-1">
        <p class="mb-0">Município/UF: {{ area.city }}/{{ area.uf }}</p>
      </div>
    </div>
    <div class="d-flex">
      <div class="col p-0">
        <table class="table table-bordered table-sm text-center">
          <tbody>
            <tr>
              <td
                class="bg-success text-white vertical-text"
                :rowspan="samples.length + 1"
              >
                <div>IDENTIFICAÇÃO</div>
              </td>
              <td class="bg-success text-white">Cód. Lab.</td>
              <td class="bg-success text-white">Descrição</td>
              <td class="bg-success text-white" :colspan="samples.length">
                Tipo de Análise
              </td>
            </tr>
            <tr v-for="(sample, index) in samples" :key="sample.id">
              <td>{{ index + 1 }}</td>
              <td>-</td>
              <td :colspan="samples.length">{{ sample.typeOfAnalysis }}</td>
            </tr>
            <tr>
              <td class="bg-success text-white vertical-text" rowspan="25">
                <div>QUÍMICA</div>
              </td>
              <td><strong>Determinação</strong></td>
              <td><strong>Unidade</strong></td>
              <td v-for="(sample, index) in samples" :key="sample.id">
                <strong> {{ index + 1 }}</strong>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                <strong>Profundidade</strong>
              </td>
              <td v-for="sample in samples" :key="sample.id">
                <strong>{{ sample.depthName }}</strong>
              </td>
            </tr>
            <tr>
              <td>pH</td>
              <td>CaCl<sub>2</sub></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.epH }}
              </td>
            </tr>
            <tr>
              <td>P resina</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.ePres }}
              </td>
            </tr>
            <tr>
              <td>K</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eKmg }}
              </td>
            </tr>
            <tr>
              <td>S-SO<sub>4</sub><sup>2-</sup></td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eS }}
              </td>
            </tr>
            <tr>
              <td>K</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eK }}
              </td>
            </tr>
            <tr>
              <td>Ca</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eCa }}
              </td>
            </tr>
            <tr>
              <td>Mg</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eMg }}
              </td>
            </tr>
            <tr>
              <td>Al<sup>3+</sup></td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eAl }}
              </td>
            </tr>
            <tr>
              <td>Al + H</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eHByAl }}
              </td>
            </tr>
            <tr>
              <td>MO</td>
              <td>g dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eMOS }}
              </td>
            </tr>
            <tr>
              <td>B</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eB }}
              </td>
            </tr>
            <tr>
              <td>Cu</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eCu }}
              </td>
            </tr>
            <tr>
              <td>Fe</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eFe }}
              </td>
            </tr>
            <tr>
              <td>Mn</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eMn }}
              </td>
            </tr>
            <tr>
              <td>Zn</td>
              <td>mg dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eZn }}
              </td>
            </tr>
            <tr>
              <td>SB</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eSB }}
              </td>
            </tr>
            <tr>
              <td>T</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eCTC }}
              </td>
            </tr>
            <tr>
              <td>t</td>
              <td>mmol<sub>c</sub> dm<sup>-3</sup></td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.et }}
              </td>
            </tr>
            <tr>
              <td>V%</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eVPercent }}
              </td>
            </tr>
            <tr>
              <td>m%</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.emPercent }}
              </td>
            </tr>
            <tr>
              <td>Ca%</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eCaPercent }}
              </td>
            </tr>
            <tr>
              <td>Mg%</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eMgPercent }}
              </td>
            </tr>
            <tr>
              <td>K%</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eKPercent }}
              </td>
            </tr>
            <tr>
              <td class="bg-success text-white vertical-text" rowspan="6">
                <div>TEXTURA</div>
              </td>
              <td>Argila</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eArgila }}
              </td>
            </tr>
            <tr>
              <td>Silte</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eSilite }}
              </td>
            </tr>
            <tr>
              <td>Areia Total</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eAreiaTotal }}
              </td>
            </tr>
            <tr>
              <td>Areia Grossa</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eAreiaGrossa }}
              </td>
            </tr>
            <tr>
              <td>Areia Fina</td>
              <td>%</td>
              <td v-for="sample in samples" :key="sample.id">
                {{ sample.eAreiaFina }}
              </td>
            </tr>
            <tr>
              <td>Classificação</td>
              <td>-</td>
              <td v-for="sample in samples" :key="sample.id">-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex">
      <div class="col">
        <p>
          <strong> Observações:</strong><br />
          pH em CaCl<sub>2</sub> 0,01 mol L<sup>-1</sup>; <br />
          S-SO<sub>4</sub><sup>2-</sup> extrator fosfato de cálcio; <br />
          P e K pelo método da resina; <br />
          Ca, Mg e Al<sup>3+</sup> extraído com KCI 1 mol L<sup>-1</sup>;
          <br />
          B extrator água quente; Cu, Fe, Mn e Zn extraído por DTPA.
        </p>
      </div>
    </div>
    <div class="d-flex">
      <div class="col border">
        <p>
          <strong> Observações do Laudo:</strong>
        </p>
      </div>
      <div class="col border text-center">
        <img
          src="@/assets/img/soil/sign/anderson_ricardo_trevisan.png"
          class="signature"
        />
        <p class="text-center">
          ANDERSON RICARDO TREVIZAN <br />
          RG: 30.684.752-8
        </p>
      </div>
      <div class="col border text-center">
        <img
          src="@/assets/img/soil/sign/edivandro_corte.png"
          class="signature"
        />
        <p class="text-center">
          EDIVANDRO CORTE <br />
          CREA/MG: 81809/D
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportPage',

  props: {
    dataReport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      area: null,
      samples: [],
    }
  },

  methods: {},

  mounted() {
    if (this.dataReport) {
      this.area = this.dataReport.area
      this.samples = this.dataReport.samples
    }
  },
}
</script>

<style scoped>
.width-100 {
  width: 120px;
}

.printable-area {
  size: A4 portrait;
}

.logo-company {
  height: 100px;
}

.vertical-text {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 1.5em;
}

.vertical-text div {
  -moz-transform: rotate(-90deg); /* FF3.5+ */
  -o-transform: rotate(-90deg); /* Opera 10.5 */
  -webkit-transform: rotate(-90deg); /* Saf3.1+, Chrome */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083); /* IE6,IE7 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)'; /* IE8 */
  margin-left: -10em;
  margin-right: -10em;
}

td {
  padding: 0.1rem !important;
}
</style>
