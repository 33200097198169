const gaussSolver = (A, b) => {
  let i
  let j
  let k
  let l
  let m

  // ETAPA DE ESCALONAMENTO
  for (k = 0; k < A.length - 1; k += 1) {
    // procura o maior k-ésimo coeficiente em módulo
    let max = Math.abs(A[k][k])
    let maxIndex = k

    for (i = k + 1; i < A.length; i += 1) {
      if (max < Math.abs(A[i][k])) {
        max = Math.abs(A[i][k])
        maxIndex = i
      }
    }

    if (maxIndex !== k) {
      /* troca a equação k pela equação com o maior k-ésimo coeficiente em módulo */

      for (j = 0; j < A.length; j += 1) {
        const temp = A[k][j]
        // eslint-disable-next-line
        A[k][j] = A[maxIndex][j]
        // eslint-disable-next-line
        A[maxIndex][j] = temp
      }

      const temp = b[k]
      // eslint-disable-next-line
      b[k] = b[maxIndex]
      // eslint-disable-next-line
      b[maxIndex] = temp
    }

    // Se A[k][k] é zero, então a matriz dos coeficiente é singular [det A = 0]
    if (A[k][k] === 0) {
      return null
    }

    for (m = k + 1; m < A.length; m += 1) {
      const F = -A[m][k] / A[k][k]
      // eslint-disable-next-line
      A[m][k] = 0 // evita uma iteração
      // eslint-disable-next-line
      b[m] = b[m] + F * b[k]

      for (l = k + 1; l < A.length; l += 1) {
        // eslint-disable-next-line
        A[m][l] = A[m][l] + F * A[k][l]
      }
    }
  }

  // ETAPA DE RESOLUÇÃO DO SISTEMA
  const X = []

  for (i = A.length - 1; i >= 0; i -= 1) {
    X[i] = b[i]
    for (j = i + 1; j < A.length; j += 1) {
      // eslint-disable-next-line
      X[i] = X[i] - X[j] * A[i][j]
    }
    // eslint-disable-next-line
    X[i] = X[i] / A[i][i]
  }
  return X
}

// eslint-disable-next-line
export { gaussSolver }
