<template>
  <div class="content">
    <reimport-analysis-file-modal
      v-if="showReimportAnalysisFileModal"
      :batchId="batch.id"
      :analysisId="1"
      @closeModal="closeReimportAnalysisFileModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link :to="`/soil/home`" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis`" title="Voltar">
            Análise de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis/${batchId}`" title="Voltar">
            Detalhes da Análise
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Absorção Atômica
        </h5>
      </div>
      <batch-details :batch="batch" />
      <div class="card-body">
        <h4 class="font-weight-light">Dados da Análise:</h4>
        <div class="row">
          <div class="col-12 d-flex">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label>Solo/Extrator Padrão:</label>
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="extractorSoilDefault"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Fator Correção Padrão: </label>
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="correctionFactorDefault"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group mt-4 text-right">
                  <button
                    class="btn btn-block btn-primary"
                    title="Aplicar a Todos"
                    @click="applyToAll()"
                  >
                    Aplicar a Todos
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-primary border-0 p-1 mb-0">
          <span class="font-weight-semibold">Observação:</span>
          Após realizar todo o preenchimento, clicar em "Salvar Dados" no final
          da página!
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-sm font-size-sm">
          <thead>
            <tr class="bg-primary text-white">
              <th colspan="2" class="text-center">Amostras</th>
              <th
                class="text-center bg-secondary text-white"
                :colspan="elements.length"
              >
                Dados Lidos
              </th>
              <th colspan="3" class="text-center bg-dark text-white">
                <div class="form-group row mb-0">
                  <div class="col-lg-12">
                    <select class="form-control" v-model="elementSelected">
                      <option
                        v-for="element in elements"
                        :key="element.id"
                        :value="element.id"
                      >
                        {{ element.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                class="bg-primary text-center text-white"
                title="Identicador Único"
              >
                #
              </th>
              <th
                class="bg-primary text-center text-white"
                title="Número da Amostra"
              >
                Nº
              </th>
              <th
                class="text-center bg-secondary text-white p-1"
                v-for="element in elements"
                :key="element.id"
                width="120"
              >
                {{ element.name }}
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Solo/Extrator
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Fator Correção
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Cálculo
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(sample, index) in samples" :key="sample.sampleId">
              <td
                class="bg-primary-100 text-center"
                :title="`Identificador único: ${sample.sampleId}`"
              >
                {{ sample.sampleId }}
              </td>
              <td class="bg-primary-100 text-center">
                {{ index + 1 }}
                <i
                  class="ml-1 fas fa-info-circle text-primary"
                  :title="`Cliente: ${sample.client}\nFazenda: ${sample.farm}\nTalhão: ${sample.area}\nProfundidade: ${sample.depthName}`"
                />
              </td>
              <!-- Valores Lido -->
              <td
                class="text-center p-1 bg-secondary-100"
                v-for="element in sample.elements"
                :key="`${sample.sampleId}_${element.id}`"
              >
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="element.value"
                />
              </td>
              <!-- Extração do Solo -->
              <td
                v-show="elementSelected === element.analysisElementId"
                class="text-center p-1 bg-secondary-100"
                v-for="element in sample.elements"
                :key="`extractor_soil_${sample.sampleId}_${element.id}`"
              >
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="element.extractorSoil"
                />
              </td>
              <!-- Fator de Correção -->
              <td
                v-show="elementSelected === element.analysisElementId"
                class="text-center p-1 bg-secondary-100"
                v-for="element in sample.elements"
                :key="`correction_soil_${sample.sampleId}_${element.id}`"
              >
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="element.correctionFactor"
                />
              </td>
              <!-- Calculo -->
              <td
                v-show="elementSelected === element.analysisElementId"
                class="text-center p-1 bg-secondary-100"
                v-for="element in sample.elements"
                :key="`calc_${sample.sampleId}_${element.id}`"
              >
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="element.calc"
                  readonly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="text-right">
          <button
            title="Reimportar Novo Arquivo"
            class="btn btn-outline-dark mr-2"
            @click="reimportFileAnalysis"
          >
            <i class="fas fa-sync-alt mr-2" />Reimportar Arquivo
          </button>
          <button
            class="btn btn-outline-success"
            title="Salvar Dados"
            :disabled="disableButton"
            @click="save()"
          >
            <i class="fas fa-save mr-2" />Salvar Dados
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'
import BatchDetails from '@/components/soil/batchs/BatchDetails.vue'
import ReimportAnalysisFileModal from '@/components/soil/analysis/ReimportAnalysisFileModal.vue'

export default {
  name: 'SoilAnalysisAtomicAbsorption',

  components: {
    LoadingData,
    BatchDetails,
    ReimportAnalysisFileModal,
  },

  data() {
    return {
      batchId: this.$route.params.batchId,
      disableButton: false,
      loadData: true,
      extractorSoilDefault: 1,
      correctionFactorDefault: 1,
      elementSelected: null,
      batch: null,
      elements: null,
      samples: null,
      showReimportAnalysisFileModal: false,
    }
  },

  watch: {
    samples: {
      handler() {
        this.calculateResults()
      },
      deep: true,
    },
  },

  methods: {
    async getSamplesByBatchAndTypeAnalysis() {
      try {
        const response = await this.$store.dispatch(
          'getSamplesByBatchAndTypeAnalysis',
          {
            batchId: this.batchId,
            analysisId: 1,
          },
        )
        this.batch = response.batch
        this.elements = response.elements
        this.elementSelected = response.elements[0].id
        this.samples = response.samples
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    calculateResults() {
      this.samples.forEach((sample, index) => {
        this.samples[index].elements.forEach((element, indexEl) => {
          this.samples[index].elements[indexEl].calc = (
            element.extractorSoil *
            element.correctionFactor *
            element.value
          ).toFixed(4)
        })
      })
    },

    applyToAll() {
      this.samples.forEach((sample, index) => {
        this.samples[index].elements.forEach((element, indexEl) => {
          if (
            this.samples[index].elements[indexEl].analysisElementId ===
            this.elementSelected
          ) {
            this.samples[index].elements[indexEl].extractorSoil =
              this.extractorSoilDefault
            this.samples[index].elements[indexEl].correctionFactor =
              this.correctionFactorDefault
          }
        })
      })
    },

    async save() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch(
          'saveSamplesFromAtomicAbsorptionAnalysis',
          { batchId: this.batchId, samples: this.samples },
        )
        this.disableButton = false
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    reimportFileAnalysis() {
      this.showReimportAnalysisFileModal = true
    },

    closeReimportAnalysisFileModal(excluded) {
      this.showReimportAnalysisFileModal = false
      if (excluded) {
        this.$router.push(PATHS.SOIL_ANALYSIS_NEW)
      }
    },
  },

  mounted() {
    this.getSamplesByBatchAndTypeAnalysis()
  },
}
</script>

<style scoped>
.width-100 {
  width: 100px;
}
</style>
