<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link :to="`/soil/home`" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis`" title="Voltar">
            Análise de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis/${batchId}`" title="Voltar">
            Detalhes da Análise
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Resultado
        </h5>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-sm font-size-sm">
          <thead>
            <tr class="bg-primary text-white">
              <th colspan="6" class="text-center">Informações</th>
              <th class="text-center bg-secondary text-white" :colspan="32">
                Dados
              </th>
            </tr>
            <tr>
              <th class="bg-primary text-center text-white fix" title="Cliente">
                Cliente
              </th>
              <th class="bg-primary text-center text-white fix" title="Fazenda">
                Fazenda
              </th>
              <th class="bg-primary text-center text-white fix" title="Talhão">
                Talhão
              </th>
              <th class="bg-primary text-center text-white fix" title="Lab">
                Lab
              </th>
              <th class="bg-primary text-center text-white fix" title="Amostra">
                Amostra
              </th>
              <th
                class="bg-primary text-center text-white"
                title="Profundidade"
              >
                Profundidade
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">pH</th>
              <th class="bg-dark text-white text-center p-1" width="120">
                P res
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                K mg
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">S</th>
              <th class="bg-dark text-white text-center p-1" width="120">K</th>
              <th class="bg-dark text-white text-center p-1" width="120">Ca</th>
              <th class="bg-dark text-white text-center p-1" width="120">Mg</th>
              <th class="bg-dark text-white text-center p-1" width="120">Al</th>
              <th class="bg-dark text-white text-center p-1" width="120">
                H/Al
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                MOS
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">B</th>
              <th class="bg-dark text-white text-center p-1" width="120">Cu</th>
              <th class="bg-dark text-white text-center p-1" width="120">Fe</th>
              <th class="bg-dark text-white text-center p-1" width="120">Mn</th>
              <th class="bg-dark text-white text-center p-1" width="120">Zn</th>
              <th class="bg-dark text-white text-center p-1" width="120">SB</th>
              <th class="bg-dark text-white text-center p-1" width="120">
                CTC
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">t</th>
              <th class="bg-dark text-white text-center p-1" width="120">V%</th>
              <th class="bg-dark text-white text-center p-1" width="120">m%</th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Ca/Mg
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Ca/K
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Mg/K
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Ca+Mg/K
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Ca%
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Mg%
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">K%</th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Argila
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Silite
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Areia Total
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Areia Grossa
              </th>
              <th class="bg-dark text-white text-center p-1" width="120">
                Areia Fina
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in results" :key="result.id">
              <td
                class="bg-primary-100 text-center"
                :title="`Cliente: ${result.clientName}`"
              >
                {{ result.clientName }}
              </td>
              <td
                class="bg-primary-100 text-center"
                :title="`Fazenda: ${result.farmName}`"
              >
                {{ result.farmName }}
              </td>
              <td
                class="bg-primary-100 text-center"
                :title="`Talhão: ${result.areaName}`"
              >
                {{ result.areaName }}
              </td>
              <td
                class="bg-primary-100 text-center"
                :title="`Contador: ${index + 1}`"
              >
                {{ index + 1 }}
              </td>
              <td
                class="bg-primary-100 text-center"
                :title="`Número da Amostra: ${result.sampleNumber}`"
              >
                {{ result.sampleNumber }}
              </td>
              <td
                class="bg-primary-100 text-center"
                :title="`Profundidade: ${result.depthName}`"
              >
                {{ result.depthName }}
              </td>
              <!-- Valores Lido -->
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].epH"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].ePres"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eKmg"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eS"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eK"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCa"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eMg"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eAl"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eHByAl"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eMOS"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eB"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCu"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eFe"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eMn"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eZn"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eSB"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCTC"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].et"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eVPercent"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].emPercent"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCaByMg"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCaByK"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eMgByK"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCaPlusMgByK"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eCaPercent"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eMgPercent"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eKPercent"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eArgila"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eSilite"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eAreiaTotal"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eAreiaGrossa"
                />
              </td>
              <td class="text-center p-1 bg-secondary-100">
                <input
                  type="number"
                  class="form-control text-center width-100 m-0"
                  v-model="results[index].eAreiaFina"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="text-right">
          <button
            class="btn btn-outline-success"
            title="Salvar Dados"
            :disabled="disableButton"
            @click="updateResultAnalysis()"
          >
            <i class="fas fa-save mr-2" />Salvar Dados
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'SoilAnalysisResult',

  components: {
    LoadingData,
  },

  data() {
    return {
      batchId: this.$route.params.batchId,
      disableButton: false,
      loadData: true,
      results: [],
    }
  },

  methods: {
    async getResults() {
      try {
        this.results = await this.$store.dispatch(
          'getResultsByBatch',
          this.batchId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async updateResultAnalysis() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch('updateResultAnalysis', {
          batchId: this.batchId,
          results: this.results,
        })
        this.disableButton = false
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getResults()
  },
}
</script>

<style scoped>
.width-100 {
  width: 120px;
}
</style>
