<template>
  <div class="row">
    <div class="col-12 col-md-4 mb-2">
      <router-link
        :to="`/soil/analysis/${batchId}/atomic-absorption`"
        :event="analysis[0].hasData ? 'click' : ''"
        class="btn btn-float btn-primary text-white w-100"
        :class="{ disabled: !analysis[0].hasData }"
        :disabled="!analysis[0].hasData"
        title="Absorção Atômica"
      >
        <i class="fas fa-atom fa-2x mb-2" />
        {{
          analysis[0].hasData
            ? 'Acessar Absorção Atômica'
            : 'Aguardando Absorção Atômica'
        }}
      </router-link>
      <p v-if="!analysis[0].hasData" class="text-center mt-2 mb-0">
        <router-link :to="`/soil/analysis/new`">
          Clique aqui para realizar a importação
        </router-link>
      </p>
    </div>
    <div class="col-12 col-md-4 mb-2">
      <router-link
        :to="`/soil/analysis/${batchId}/spectrophotometry`"
        :event="analysis[1].hasData ? 'click' : ''"
        class="btn btn-float btn-purple text-white w-100"
        :class="{ disabled: !analysis[1].hasData }"
        :disabled="!analysis[1].hasData"
        title="Espectrofotometria"
      >
        <i class="fas fa-glasses fa-2x mb-2" />
        {{
          analysis[1].hasData
            ? 'Acessar Espectrofotometria'
            : 'Aguardando Dados de Espectrofotometria'
        }}
      </router-link>
      <p v-if="!analysis[1].hasData" class="text-center mt-2 mb-0">
        <router-link :to="`/soil/analysis/new`">
          Clique aqui para realizar a importação
        </router-link>
      </p>
    </div>
    <div class="col-12 col-md-4 mb-2">
      <router-link
        :to="`/soil/analysis/${batchId}/titration`"
        :event="analysis[2].hasData ? 'click' : ''"
        class="btn btn-float btn-teal text-white w-100"
        :class="{ disabled: !analysis[2].hasData }"
        :disabled="!analysis[2].hasData"
        title="Titulometria"
      >
        <i class="fas fa-vial fa-2x mb-2" />
        {{
          analysis[2].hasData
            ? 'Acessar Titulometria'
            : 'Aguardando Dados de Titulometria'
        }}
      </router-link>
      <p v-if="!analysis[2].hasData" class="text-center mt-2 mb-0">
        <router-link :to="`/soil/analysis/new`">
          Clique aqui para realizar a importação
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusByAnalysis',

  props: {
    batchId: {
      type: String,
      required: true,
    },
    analysis: {
      type: Array,
      required: true,
    },
  },
}
</script>
