<template>
  <div class="">
    <loading-data v-if="loadData" />
    <div v-else>
      <h4 class="font-weight-light">Amostras:</h4>
      <div v-if="firstStep">
        <table class="table table-striped">
          <thead>
            <tr class="text-center">
              <th>Quantidade de Amostras</th>
              <th :colspan="depths.length">Profundidades</th>
            </tr>
            <tr class="text-center">
              <th>
                <div class="input-group mt-2">
                  <span
                    v-if="shipment.numberOfSamples > 1"
                    role="button"
                    class="input-group-prepend"
                    @click="increaseOrDecreaseNumberOfSamples('-')"
                    title="Diminuir"
                  >
                    <span
                      class="input-group-text bg-primary border-primary text-white"
                    >
                      <i class="fas fa-minus-circle" />
                    </span>
                  </span>
                  <input
                    type="number"
                    step="1"
                    class="form-control text-center"
                    placeholder="Número de Amostras"
                    v-model="shipment.numberOfSamples"
                    readonly
                  />
                  <span
                    role="button"
                    class="input-group-append"
                    @click="increaseOrDecreaseNumberOfSamples('+')"
                    title="Aumentar"
                  >
                    <span
                      class="input-group-text bg-primary border-primary text-white"
                    >
                      <i class="fas fa-plus-circle" />
                    </span>
                  </span>
                </div>
              </th>
              <th v-for="depth in depths" :key="'thdepth' + depth.id">
                <div class="text-center" :title="`Profundidade: ${depth.name}`">
                  {{ depth.name }}
                </div>
                <div>
                  <div class="custom-control custom-checkbox text-center">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`depthAll_${depth.id}`"
                      :value="depth.id"
                      @change="applyOnChildrenDepths($event)"
                    />
                    <label
                      class="custom-control-label my-1"
                      :for="`depthAll_${depth.id}`"
                    >
                    </label>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sample in samples" :key="sample.key">
              <td>{{ sample.name }}</td>
              <td
                v-for="depth in sample.depths"
                :key="depth.key"
                class="text-center"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`depth_${depth.key}`"
                    :value="depth"
                    v-model="selectedDepths"
                  />
                  <label
                    class="custom-control-label my-1"
                    :for="`depth_${depth.key}`"
                  >
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-right mt-3">
          <button
            class="btn btn-outline-primary"
            title="Avançar (Selecionar Tipos de Análises)"
            @click="nextStep()"
          >
            <i class="fas fa-arrow-circle-right mr-2" />Avançar
          </button>
        </div>
      </div>
      <div v-else>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Amostras</th>
              <th>Profundidades</th>
              <th
                v-for="typeOfAnalysis in typesOfAnalysis"
                :key="'thtypeOfAnalysis' + typeOfAnalysis.id"
              >
                <div
                  class="text-center"
                  :title="`Tipo de Análise: ${
                    typeOfAnalysis.name
                  } com valor ${moneyToCurrencyBr(typeOfAnalysis.price)}`"
                >
                  {{ typeOfAnalysis.name }}
                </div>
                <div>
                  <div class="custom-control custom-checkbox text-center">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`typeOfAnalysisAll_${typeOfAnalysis.id}`"
                      :value="typeOfAnalysis.id"
                      @change="applyOnChildrenTypeOfAnalysis($event)"
                    />
                    <label
                      class="custom-control-label my-1"
                      :for="`typeOfAnalysisAll_${typeOfAnalysis.id}`"
                    >
                    </label>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-for="sample in samples" :key="sample.number">
            <tr
              v-for="(depth, index) in sample.selectedDepths"
              :key="depth.depthId"
            >
              <td v-if="index === 0" :rowspan="sample.selectedDepths.length">
                {{ sample.name }}
              </td>
              <td>{{ depth.depthName }}</td>
              <td
                v-for="typeOfAnalysis in depth.typesOfAnalysis"
                :key="typeOfAnalysis.key"
                class="text-center"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`typeOfAnalysis_${typeOfAnalysis.key}`"
                    :value="typeOfAnalysis"
                    v-model="selectedTypesOfAnalysis"
                  />
                  <label
                    class="custom-control-label my-1"
                    :for="`typeOfAnalysis_${typeOfAnalysis.key}`"
                  >
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-right mt-3">
          <button
            class="btn btn-outline-dark mr-2"
            title="Reiniciar Cadastro"
            @click="restartRegistration()"
          >
            <i class="fas fa-sync-alt mr-2" />Reiniciar Cadastro
          </button>
          <button
            class="btn btn-outline-primary"
            title="Finalizar Remessa"
            @click="saveShipment()"
          >
            <i class="fas fa-dolly mr-2" />Finalizar Remessa
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { moneyToCurrencyBr } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'SelectSample',

  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      firstStep: true,
      depths: [],
      typesOfAnalysis: [],
      samples: [],
      selectedDepths: [],
      selectedTypesOfAnalysis: [],
      moneyToCurrencyBr,
    }
  },

  watch: {
    'shipment.numberOfSamples': {
      handler(newVal, oldVal) {
        this.formatInputsCheckbox()
      },
    },
  },

  methods: {
    async getDepths() {
      try {
        this.depths = await this.$store.dispatch('getDepths', { status: 1 })
        this.getTypesOfAnalysis()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getTypesOfAnalysis() {
      try {
        this.typesOfAnalysis = await this.$store.dispatch(
          'getTypesOfAnalysis',
          { status: 1 },
        )
        this.formatInputsCheckbox()
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    formatInputsCheckbox() {
      this.samples = []

      for (let i = 1; i <= this.shipment.numberOfSamples; i += 1) {
        const sample = {
          number: i,
          name: `Amostra ${i}`,
          depths: [],
        }

        this.depths.forEach((depth) => {
          const element = {
            key: `depth${i}_${depth.id}`,
            id: depth.id,
            sampleNumber: i,
            name: depth.name,
            typesOfAnalysis: [],
          }

          this.typesOfAnalysis.forEach((typeOfAnalysis) => {
            const elementTypeOfAnalysis = {
              key: `${element.key}_typeOfAnalysis${i}_${typeOfAnalysis.id}`,
              id: typeOfAnalysis.id,
              sampleNumber: i,
              depthId: element.id,
              name: typeOfAnalysis.name,
              price: typeOfAnalysis.price,
            }
            element.typesOfAnalysis.push(elementTypeOfAnalysis)
          })

          sample.depths.push(element)
        })

        this.samples.push(sample)
      }
    },

    increaseOrDecreaseNumberOfSamples(type) {
      this.shipment.numberOfSamples = parseInt(
        this.shipment.numberOfSamples,
        10,
      )
      if (type === '+') {
        this.shipment.numberOfSamples += 1
      } else if (type === '-') {
        this.removeLastElementArrays()
        this.shipment.numberOfSamples -= 1
      }
    },

    removeLastElementArrays() {
      const numberLastSample = this.samples.length

      this.selectedDepths = this.selectedDepths.reduce(
        // eslint-disable-next-line no-sequences
        (p, c) => (c.sampleNumber !== numberLastSample && p.push(c), p),
        [],
      )
    },

    applyOnChildrenDepths(event) {
      const idDepth = event.target.value
      const isChecked = event.currentTarget.checked

      this.samples.forEach((sample) => {
        const elements = sample.depths
          .filter((element) => {
            return element.id === idDepth
          })
          .map((element) => element)
        if (isChecked) {
          this.selectedDepths = [
            ...new Set([...this.selectedDepths, ...elements]),
          ]
        } else {
          this.selectedDepths = this.selectedDepths.filter(
            (element) => !elements.includes(element),
          )
        }
      })
    },

    nextStep() {
      let isInvalid = false
      const samplesToSave = []

      this.samples.forEach((sample) => {
        const selectedDepths = []

        this.selectedDepths.forEach((depth) => {
          if (depth.sampleNumber === sample.number) {
            selectedDepths.push({
              depthId: depth.id,
              depthName: depth.name,
              typesOfAnalysis: depth.typesOfAnalysis,
            })
          }
        })

        if (selectedDepths.length === 0) {
          isInvalid = true
          this.$root.$children[0].$refs.notification.warning(
            `Nenhuma profundidade foi selecionada na ${sample.name}`,
          )
        }

        samplesToSave.push({
          number: sample.number,
          name: sample.name,
          selectedDepths,
        })
      })

      if (!isInvalid) {
        this.samples = samplesToSave
        this.firstStep = false
      }
    },

    applyOnChildrenTypeOfAnalysis(event) {
      const idTypeOfAnalysis = event.target.value
      const isChecked = event.currentTarget.checked

      this.samples.forEach((sample) => {
        sample.selectedDepths.forEach((depth) => {
          const elements = depth.typesOfAnalysis
            .filter((element) => {
              return element.id === idTypeOfAnalysis
            })
            .map((element) => element)
          if (isChecked) {
            this.selectedTypesOfAnalysis = [
              ...new Set([...this.selectedTypesOfAnalysis, ...elements]),
            ]
          } else {
            this.selectedTypesOfAnalysis = this.selectedTypesOfAnalysis.filter(
              (element) => !elements.includes(element),
            )
          }
        })
      })
    },

    restartRegistration() {
      this.selectedDepths = []
      this.selectedTypesOfAnalysis = []
      this.formatInputsCheckbox()
      this.firstStep = true
    },

    prepareToSave() {
      let isInvalid = false
      const samplesToSave = []

      this.samples.forEach((sample) => {
        sample.selectedDepths.forEach((depth) => {
          const selectedTypesOfAnalysis = []

          this.selectedTypesOfAnalysis.forEach((typeOfAnalysis) => {
            if (
              typeOfAnalysis.sampleNumber === sample.number &&
              typeOfAnalysis.depthId === depth.depthId
            ) {
              selectedTypesOfAnalysis.push({
                typeOfAnalysisId: typeOfAnalysis.id,
                typeOfAnalysisName: typeOfAnalysis.name,
                typeOfAnalysisPrice: typeOfAnalysis.price,
              })
            }
          })

          if (selectedTypesOfAnalysis.length === 0) {
            isInvalid = true
            this.$root.$children[0].$refs.notification.warning(
              `Nenhum tipo de análise foi selecionada na ${sample.name} e pronfundidade ${depth.depthName}`,
            )
          }

          samplesToSave.push({
            number: sample.number,
            name: sample.name,
            depth: {
              depthId: depth.depthId,
              depthName: depth.depthName,
              typesOfAnalysis: selectedTypesOfAnalysis,
            },
          })
        })
      })

      if (isInvalid) {
        return false
      }
      return samplesToSave
    },

    async saveShipment() {
      this.disableButton = true
      try {
        const samples = this.prepareToSave()
        if (samples) {
          const response = await this.$store.dispatch('updateShipment', {
            id: this.shipment.id,
            samples,
          })
          this.$root.$children[0].$refs.notification.success(response.message)

          this.$router.push({
            name: 'SoilShipments',
          })
        }
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getDepths()
  },
}
</script>
