<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar"> Módulo de Solos </router-link>
          <i class="fas fa-angle-right ml-2" />
          Remessas
        </h5>
        <div v-if="getModuleUserByCode('3.03.002')" class="header-elements">
          <router-link
            to="/soil/shipments/new"
            class="btn btn-primary"
            title="Nova Remessa"
          >
            Nova Remessa
          </router-link>
        </div>
      </div>
      <list-shipments :shipments="shipments" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListShipments from '@/components/soil/shipments/ListShipments.vue'

export default {
  name: 'SoilShipments',

  components: {
    LoadingData,
    ListShipments,
  },

  data() {
    return {
      loadData: true,
      shipments: [],
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getShipments() {
      try {
        this.shipments = await this.$store.dispatch('getShipments')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getShipments()
  },
}
</script>
