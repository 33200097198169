<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link :to="`/soil/home`" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis`" title="Voltar">
            Análise de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Detalhes da Análise
        </h5>
        <div class="header-elements">
          <span :class="`btn btn-${batch.statusClass}`">
            {{ batch.statusName }}
          </span>
        </div>
      </div>
      <div class="card-body p-0">
        <batch-details :batch="batch" />
      </div>
      <div class="card-body">
        <h4 class="font-weight-light">Status das Análises:</h4>
        <status-by-analysis :batchId="batchId" :analysis="analysis" />
      </div>
      <div class="card-body">
        <h4 class="font-weight-light">Processamento das Análises:</h4>
        <process-data-by-cards
          :batchId="batchId"
          :analysis="analysis"
          :analysisPendent="analysisPendent"
        />
      </div>
      <div class="card-body">
        <h4 class="font-weight-light">Arquivos de Preenchimento:</h4>
        <download-template :batchId="batchId" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import BatchDetails from '@/components/soil/batchs/BatchDetails.vue'
import StatusByAnalysis from '@/components/soil/analysis/StatusByAnalysis.vue'
import ProcessDataByCards from '@/components/soil/analysis/ProcessDataByCards.vue'
import DownloadTemplate from '@/components/soil/batchs/DownloadTemplate.vue'

export default {
  name: 'SoilAnalysisDetails',

  components: {
    LoadingData,
    BatchDetails,
    StatusByAnalysis,
    ProcessDataByCards,
    DownloadTemplate,
  },

  data() {
    return {
      loadData: true,
      batchId: this.$route.params.batchId,
      batch: null,
      analysis: null,
      analysisPendent: true,
    }
  },

  methods: {
    async getBatch() {
      try {
        this.batch = await this.$store.dispatch('getBatch', this.batchId)
        this.getStatusAnalysisByBatch()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getStatusAnalysisByBatch() {
      try {
        this.analysis = await this.$store.dispatch(
          'getStatusAnalysisByBatch',
          this.batchId,
        )
        this.verifyAnalysisPendent()
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    verifyAnalysisPendent() {
      const hasPendent = this.analysis.filter(
        (analysis) => analysis.hasData === false,
      )

      if (hasPendent.length === 0) {
        this.analysisPendent = false
      }
    },
  },

  mounted() {
    this.getBatch()
  },
}
</script>
