<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar"> Módulo de Solos </router-link>
          <i class="fas fa-angle-right ml-2" />
          Lotes
        </h5>
        <div v-if="getModuleUserByCode('3.04.002')" class="header-elements">
          <router-link
            to="/soil/batchs/new"
            class="btn btn-primary"
            title="Criar Novo Lote de Remessas"
          >
            Novo Lote
          </router-link>
        </div>
      </div>
      <list-batchs :batchs="batchs" :segment="`batchs`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListBatchs from '@/components/soil/batchs/ListBatchs.vue'

export default {
  name: 'SoilBatchs',

  components: {
    LoadingData,
    ListBatchs,
  },

  data() {
    return {
      loadData: true,
      batchs: [],
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getBatchs() {
      try {
        this.batchs = await this.$store.dispatch('getBatchs')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getBatchs()
  },
}
</script>
