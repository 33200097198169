<template>
  <div class="content">
    <delete-shipment-modal
      v-if="showDeleteShipmentModal"
      :shipmentId="shipment.id"
      @closeModal="closeDeleteShipmentModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="/soil/home" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/soil/shipments" title="Voltar">
            Remessas
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Detalhes da Remessa
        </h5>
        <div class="header-elements">
          <span :class="`btn btn-${shipment.statusClass}`">
            {{ shipment.statusName }}
          </span>
        </div>
      </div>
      <div class="card-body">
        <h4 class="font-weight-light">Dados da Remessa:</h4>
        <div class="row">
          <div class="col-md-6 col-12">
            <p><strong>Número da Remessa:</strong> {{ shipment.id }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p><strong>Cliente:</strong> {{ shipment.clientName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p><strong>Fazenda:</strong> {{ shipment.farmName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p><strong>Talhão:</strong> {{ shipment.areaName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>Criado por:</strong>
              {{ createdUpdatedUser(shipment.createdBy, shipment.createdAt) }}
            </p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>Atualizado por:</strong>
              {{ createdUpdatedUser(shipment.updatedBy, shipment.updatedAt) }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <select-sample v-if="shipment.status == 100" :shipment="shipment" />
        <selected-sample v-else :shipment="shipment" />
        <div
          v-if="
            (shipment.status == 100 || shipment.status == 110) &&
            getModuleUserByCode('3.03.003')
          "
          class="text-right"
        >
          <hr />
          <button
            class="btn btn-outline-danger"
            title="Excluir Remessa"
            @click="deleteShipment()"
          >
            <i class="fas fa-trash-alt mr-2" />Excluir Remessa
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateTimeSqlToDateBR } from '@/utils/date'
import { createdUpdatedUser } from '@/utils/strings'
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'
import SelectSample from '@/components/soil/shipments/SelectSample.vue'
import SelectedSample from '@/components/soil/shipments/SelectedSample.vue'
import DeleteShipmentModal from '@/components/soil/shipments/DeleteShipmentModal.vue'

export default {
  name: 'SoilShipmentDetails',

  components: {
    LoadingData,
    SelectSample,
    SelectedSample,
    DeleteShipmentModal,
  },

  data() {
    return {
      loadData: true,
      showDeleteShipmentModal: false,
      shipment: {},
      dateTimeSqlToDateBR,
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getShipment() {
      try {
        this.shipment = await this.$store.dispatch(
          'getShipment',
          this.$route.params.shipmentId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    deleteShipment() {
      this.showDeleteShipmentModal = true
    },

    closeDeleteShipmentModal(excluded) {
      this.showDeleteShipmentModal = false
      if (excluded) {
        this.$router.push(PATHS.SOIL_SHIPMENTS)
      }
    },
  },

  mounted() {
    this.getShipment()
  },
}
</script>
