<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/soil/home" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/soil/shipments" title="Voltar">
            Remessas
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Nova Remessa
        </h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="save">
          <div class="form-row">
            <div class="form-group col-12 col-sm-6">
              <label>Cliente: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.shipment.clientId.$error }"
                v-model="$v.shipment.clientId.$model"
              >
                <option value="">Selecione um Cliente</option>
                <option
                  v-for="client in clients"
                  :key="client.id"
                  :value="client.id"
                  :title="client.name"
                >
                  {{ client.name }}
                </option>
              </select>
              <span
                v-if="!$v.shipment.clientId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-6">
              <label>Fazenda: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.shipment.farmId.$error }"
                v-model="$v.shipment.farmId.$model"
              >
                <option value="">Selecione uma Fazenda</option>
                <option
                  v-for="farm in farms"
                  :key="farm.id"
                  :value="farm.id"
                  :title="farm.name"
                >
                  {{ farm.name }}
                </option>
              </select>
              <span
                v-if="!$v.shipment.farmId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-6">
              <label>Talhão: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.shipment.areaId.$error }"
                v-model="$v.shipment.areaId.$model"
              >
                <option value="">Selecione um Talhão</option>
                <option
                  v-for="area in areas"
                  :key="area.id"
                  :value="area.id"
                  :title="area.name"
                >
                  {{ area.name }}
                </option>
              </select>
              <span
                v-if="!$v.shipment.areaId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-6">
              <label>
                Número de Amostras: <span class="text-danger">*</span>
              </label>
              <input
                type="number"
                min="1"
                class="form-control"
                :class="{ 'is-invalid': $v.shipment.numberOfSamples.$error }"
                placeholder="Quantidade"
                v-model="$v.shipment.numberOfSamples.$model"
              />
              <span
                v-if="!$v.shipment.numberOfSamples.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="text-right">
            <router-link to="/soil/home" class="btn btn-link" title="Cancelar">
              Cancelar
            </router-link>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar Remessa"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Cadastrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SoilShipmentNew',

  data() {
    return {
      clients: [],
      farms: [],
      areas: [],
      shipment: {
        clientId: '',
        farmId: '',
        areaId: '',
        numberOfSamples: '',
      },
      disableButton: false,
    }
  },

  watch: {
    'shipment.clientId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.getFarms()
        }
        this.farms = null
        this.shipment.farmId = ''
      },
    },

    'shipment.farmId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.getAreas()
        }
        this.areas = null
        this.shipment.areaId = ''
      },
    },
  },

  methods: {
    async getClients() {
      try {
        this.clients = await this.$store.dispatch('getClients', {
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getFarms() {
      try {
        this.farms = await this.$store.dispatch('getFarms', {
          clientId: this.shipment.clientId,
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getAreas() {
      try {
        this.areas = await this.$store.dispatch('getAreas', {
          clientId: this.shipment.clientId,
          farmId: this.shipment.farmId,
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'saveShipment',
            this.shipment,
          )
          this.disableButton = false
          this.$root.$children[0].$refs.notification.success(response.message)
          this.$router.push({
            name: 'SoilShipmentDetails',
            params: { shipmentId: response.data },
          })
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    shipment: {
      clientId: {
        required,
      },
      farmId: {
        required,
      },
      areaId: {
        required,
      },
      numberOfSamples: {
        required,
      },
    },
  },

  mounted() {
    this.getClients()
  },
}
</script>
