<template>
  <div class="content p-0">
    <loading-data v-if="loadData" />
    <div v-else>
      <h4 class="font-weight-light">Amostras:</h4>
      <table class="table table-xs table-striped">
        <thead>
          <tr class="text-white bg-secondary">
            <th>Amostra</th>
            <th>Profundidade</th>
            <th>Análises Solicitadas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sample in samples" :key="sample.id">
            <td>Amostra {{ sample.number }}</td>
            <td>
              {{ sample.depthName }}
            </td>
            <td>
              <div
                v-for="item in sample.analysis"
                :key="`${shipment.id}_${sample.id}_${item.id}`"
              >
                {{ item.typeOfAnalysisName }} ({{
                  moneyToCurrencyBr(item.typeOfAnalysisPrice)
                }})
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { moneyToCurrencyBr } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'SelectedSample',

  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      samples: [],
      moneyToCurrencyBr,
    }
  },

  methods: {
    async getSamples() {
      try {
        this.samples = await this.$store.dispatch(
          'getSamples',
          this.shipment.id,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getSamples()
  },
}
</script>
