<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/soil/home" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/soil/analysis" title="Voltar">
            Análise de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Importar Análise de Solos
        </h5>
      </div>
      <div class="card-body">
        <form
          v-if="!isSaving && !isValidation"
          enctype="multipart/form-data"
          novalidate
        >
          <h4 class="font-weight-light text-center">Selecione uma Análise:</h4>
          <div class="form-group">
            <div class="d-flex justify-content-center">
              <div
                v-for="item in analysis"
                :key="item.id"
                class="custom-control custom-radio custom-control-inline"
              >
                <input
                  type="radio"
                  class="custom-control-input"
                  :id="`analysis_${item.id}`"
                  :value="item.id"
                  v-model="analysisId"
                />
                <label
                  class="custom-control-label"
                  :for="`analysis_${item.id}`"
                >
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
          <div v-if="analysisId" class="form-group text-center mt-3 mb-0">
            <h4 class="font-weight-light mb-3">
              Agora selecione o arquivo para upload:
            </h4>
            <label
              class="btn btn-primary-100 text-primary border-primary p-3"
              title="Selecione o arquivo de acordo com o tipo de análise selecionada"
            >
              <input type="file" @change="onFilePicked" />
              <i class="fas fa-cloud-upload-alt fa-3x" />
            </label>
          </div>
        </form>
        <div v-if="isSaving" class="text-center">
          <h4 class="font-weight-light">Processando Arquivo...</h4>
        </div>
        <div v-if="isValidation && dataFromFile.length > 0" class="text-center">
          <h4 class="font-weight-light mb-0">
            Verifique se os dados estão corretos antes de finalizar a importação
          </h4>
        </div>
      </div>
      <div v-if="isValidation && dataFromFile.length > 0">
        <div class="table-responsive">
          <table class="table table-striped table-sm font-size-sm">
            <tbody>
              <tr v-for="(row, index) in dataFromFile" :key="index">
                <td
                  v-for="(item, rowIndex) in row"
                  :key="rowIndex"
                  :class="{ 'font-weight-bold': index == 0 }"
                >
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body">
          <div class="text-right">
            <button
              class="btn btn-outline-dark mr-2"
              title="Reiniciar Importação"
              @click="restartImportation()"
            >
              <i class="fas fa-sync-alt mr-2" />
              Reiniciar Importação
            </button>
            <button
              class="btn btn-outline-primary"
              title="Finalizar Importação"
              :disabled="disableButton"
              @click="startImportation()"
            >
              <i class="fas fa-check-circle mr-2" />
              Finalizar Importação
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nameAnalysis } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'SoilAnalysisNew',

  components: {
    LoadingData,
  },

  data() {
    return {
      loadData: true,
      isSaving: false,
      isValidation: false,
      disableButton: false,
      analysis: null,
      analysisId: null,
      file: null,
      dataFromFile: [],
    }
  },

  methods: {
    async getAnalysis() {
      try {
        this.analysis = await this.$store.dispatch('getAnalysis')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    onFilePicked(event) {
      const { files } = event.target
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      const [file] = files
      this.file = file
      this.processUpload()
    },

    async processUpload() {
      this.isSaving = true
      try {
        const formData = new FormData()
        formData.append('analysisId', this.analysisId)
        formData.append('file', this.file)
        const response = await this.$store.dispatch(
          'uploadAnalysisFile',
          formData,
        )
        this.isSaving = false
        this.isValidation = true
        this.dataFromFile = response.data
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.isSaving = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async startImportation() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('saveAnalysis', {
          analysisId: this.analysisId,
          data: this.dataFromFile,
        })
        this.$router.push({
          name: nameAnalysis(this.analysisId),
          params: { batchId: this.dataFromFile[1][1] },
        })
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    restartImportation() {
      this.isSaving = false
      this.isValidation = false
      this.analysisId = null
      this.file = null
      this.dataFromFile = []
    },
  },

  mounted() {
    this.getAnalysis()
  },
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
