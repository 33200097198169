<template>
  <div class="card-body">
    <h4 class="font-weight-light">Dados do Lote:</h4>
    <div class="row">
      <div class="col-md-6 col-12">
        <p>
          <strong>Número do Lote: </strong>
          <router-link
            :to="`/soil/batchs/${batch.id}`"
            title="Acessar detalhes do lote"
          >
            {{ batch.id }}
          </router-link>
        </p>
      </div>
      <div class="col-md-6 col-12">
        <p><strong>Número de Amostras: </strong> {{ batch.numberOfSamples }}</p>
      </div>
      <div class="col-md-6 col-12">
        <p>
          <strong>Criado por:</strong>
          {{ createdUpdatedUser(batch.createdBy, batch.createdAt) }}
        </p>
      </div>
      <div class="col-md-6 col-12">
        <p>
          <strong>Atualizado por:</strong>
          {{ createdUpdatedUser(batch.updatedBy, batch.updatedAt) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'BatchDetails',

  props: {
    batch: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      createdUpdatedUser,
    }
  },
}
</script>
