<template>
  <div class="content">
    <div class="card mb-0">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/soil/home" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/soil/batchs" title="Voltar"> Lotes </router-link>
          <i class="fas fa-angle-right ml-2" />
          Novo Lote
        </h5>
      </div>
      <div v-if="loadData">
        <loading-data />
      </div>
      <div v-else class="card-body">
        <div v-if="shipments.length > 0">
          <div class="d-flex justify-content-between">
            <h6>Existem {{ shipments.length }} remessas</h6>
            <h6>Total de amostras selecionadas: {{ numberOfSamples }}</h6>
          </div>
          <div v-for="(shipment, index) in shipments" :key="shipment.id">
            <div class="py-2">
              <div class="alert alert-dark d-flex justify-content-between mb-0">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`type_of_analysis_${shipment.id}`"
                    :value="shipment"
                    v-model="shipmentsSelecteds"
                  />
                  <label
                    class="custom-control-label"
                    :for="`type_of_analysis_${shipment.id}`"
                  >
                    Remessa {{ shipment.id }}
                  </label>
                </div>
                <div class="d-flex">
                  <p class="mb-0 mr-2">
                    <strong>Nº de Amostras:</strong>
                    {{ shipment.numberOfSamples }}
                  </p>
                  <span
                    v-if="!shipment.showDetails"
                    role="button"
                    class="badge bg-primary text-white"
                    title="Detalhar"
                    @click="showOrHideDetails(index, true)"
                  >
                    Detalhes
                  </span>
                  <span
                    v-else
                    role="button"
                    class="badge bg-warning text-white"
                    title="Ocultar"
                    @click="showOrHideDetails(index, false)"
                  >
                    Ocultar
                  </span>
                </div>
              </div>
            </div>
            <div v-if="shipment.showDetails">
              <div class="row">
                <div class="col-12">
                  <h4 class="font-weight-light">Dados da Remessa:</h4>
                </div>
                <div class="col-12 col-md-4">
                  <p><strong>Cliente:</strong> {{ shipment.clientName }}</p>
                </div>
                <div class="col-12 col-md-4">
                  <p><strong>Fazenda:</strong> {{ shipment.farmName }}</p>
                </div>
                <div class="col-12 col-md-4">
                  <p><strong>Talhão:</strong> {{ shipment.areaName }}</p>
                </div>
                <div class="col-md-6 col-12">
                  <p>
                    <strong>Criado por:</strong>
                    {{
                      createdUpdatedUser(shipment.createdBy, shipment.createdAt)
                    }}
                  </p>
                </div>
                <div class="col-md-6 col-12">
                  <p>
                    <strong>Atualizado por:</strong>
                    {{
                      createdUpdatedUser(shipment.updatedBy, shipment.updatedAt)
                    }}
                  </p>
                </div>
              </div>
              <selected-sample :shipment="shipment"></selected-sample>
              <hr />
            </div>
          </div>
          <div class="text-right mt-2">
            <button
              class="btn btn-primary"
              title="Gerar Lote"
              :disabled="disableButton"
              @click="save()"
            >
              <span>Gerar Lote</span>
            </button>
          </div>
        </div>
        <div v-else class="text-center">
          <p>Para criação de lotes é necessário existir remessas</p>
          <div v-if="getModuleUserByCode('3.04.002')">
            <router-link
              to="/soil/shipments/new"
              class="btn btn-primary"
              title="Criar Nova Remessa"
            >
              Criar Nova Remessa
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createdUpdatedUser } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'
import SelectedSample from '@/components/soil/shipments/SelectedSample.vue'

export default {
  name: 'SoilBatchNew',

  components: {
    LoadingData,
    SelectedSample,
  },

  data() {
    return {
      loadData: true,
      shipments: [],
      shipmentsSelecteds: [],
      numberOfSamples: 0,
      disableButton: true,
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  watch: {
    shipmentsSelecteds: {
      handler(newVal, oldVal) {
        this.calculateNumberOfSamples()
      },
    },
  },

  methods: {
    async getShimentsAvaliablesToBatch() {
      try {
        this.shipments = await this.$store.dispatch(
          'getShimentsAvaliablesToBatch',
        )
        if (this.shipments.length > 0) {
          this.shipments = this.shipments.map((shipment) => ({
            ...shipment,
            showDetails: false,
          }))
        }
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    showOrHideDetails(index, value) {
      this.shipments[index].showDetails = value
    },

    calculateNumberOfSamples() {
      if (this.shipmentsSelecteds.length === 0) {
        this.numberOfSamples = 0
        this.disableButton = true
      } else {
        this.numberOfSamples = 0
        this.disableButton = false
        this.shipmentsSelecteds.forEach((shipment) => {
          this.numberOfSamples += Number(shipment.numberOfSamples)
        })
      }
    },

    async save() {
      if (this.shipmentsSelecteds.length > 0) {
        try {
          const response = await this.$store.dispatch(
            'saveBatch',
            this.shipmentsSelecteds,
          )
          this.disableButton = false
          this.$root.$children[0].$refs.notification.success(response.message)
          this.$router.push({
            name: 'SoilBatchs',
          })
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      } else {
        this.$root.$children[0].$refs.notification.warning(
          'Selecione pelo menos uma remessa.',
        )
      }
    },
  },

  mounted() {
    this.getShimentsAvaliablesToBatch()
  },
}
</script>
