<template>
  <div class="content">
    <cancel-batch-modal
      v-if="showCancelBatchModal"
      :batchId="batch.id"
      @closeModal="closeCancelBatchModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="/soil/home" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/soil/batchs" title="Voltar"> Lotes </router-link>
          <i class="fas fa-angle-right ml-2" />
          Detalhes do Lote
        </h5>
        <div class="header-elements">
          <span :class="`btn btn-${batch.statusClass}`">
            {{ batch.statusName }}
          </span>
        </div>
      </div>
      <batch-details :batch="batch" />
      <div class="p-0">
        <ul class="nav nav-tabs nav-tabs-highlight nav-justified">
          <li class="nav-item">
            <a href="#batch-details" class="nav-link active" data-toggle="tab">
              Detalhes do Lote
            </a>
          </li>
          <li class="nav-item">
            <a href="#batch-templates" class="nav-link" data-toggle="tab">
              Download dos Modelos
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="batch-details">
            <div class="card-body py-1">
              <div v-for="shipment in batch.shipments" :key="shipment.id">
                <div class="row">
                  <div class="col-12">
                    <h4 class="font-weight-light">Dados da Remessa:</h4>
                  </div>
                  <div class="col-md-6 col-12">
                    <p>
                      <strong>Número da Remessa:</strong>
                      {{ shipment.id }}
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p>
                      <strong>Quantidade de Amostras:</strong>
                      {{ shipment.numberOfSamples }}
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p><strong>Cliente:</strong> {{ shipment.clientName }}</p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p>
                      <strong>Fazenda e Talhão:</strong>
                      {{ shipment.farmName }} | {{ shipment.areaName }}
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p>
                      <strong>Criado por:</strong>
                      {{
                        createdUpdatedUser(
                          shipment.createdBy,
                          shipment.createdAt,
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-md-6 col-12">
                    <p>
                      <strong>Atualizado por:</strong>
                      {{
                        createdUpdatedUser(
                          shipment.updatedBy,
                          shipment.updatedAt,
                        )
                      }}
                    </p>
                  </div>
                </div>
                <selected-sample :shipment="shipment" />
              </div>
            </div>
          </div>
          <div class="tab-pane px-3 fade" id="batch-templates">
            <download-template :batchId="batchId" />
          </div>
        </div>
      </div>
      <div class="card-body">
        <div
          v-if="
            (batch.status == 200 || batch.status == 210) &&
            getModuleUserByCode('3.04.003')
          "
          class="text-right"
        >
          <button
            class="btn btn-outline-danger"
            title="Cancelar Lote"
            @click="cancelBatch()"
          >
            <i class="fas fa-ban mr-2" />Cancelar Lote
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createdUpdatedUser } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'
import BatchDetails from '@/components/soil/batchs/BatchDetails.vue'
import SelectedSample from '@/components/soil/shipments/SelectedSample.vue'
import DownloadTemplate from '@/components/soil/batchs/DownloadTemplate.vue'
import CancelBatchModal from '@/components/soil/batchs/CancelBatchModal.vue'

export default {
  name: 'SoilBatchDetails',

  components: {
    LoadingData,
    BatchDetails,
    SelectedSample,
    DownloadTemplate,
    CancelBatchModal,
  },

  data() {
    return {
      loadData: true,
      batchId: this.$route.params.batchId,
      batch: {},
      showCancelBatchModal: false,
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getBatch() {
      try {
        this.batch = await this.$store.dispatch('getBatch', this.batchId)
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    cancelBatch() {
      this.showCancelBatchModal = true
    },

    closeCancelBatchModal(loadData) {
      if (loadData) {
        this.getBatch()
      }
      this.showCancelBatchModal = false
    },
  },

  mounted() {
    this.getBatch()
  },
}
</script>
