<template>
  <div class="content">
    <reimport-analysis-file-modal
      v-if="showReimportAnalysisFileModal"
      :batchId="batch.id"
      :analysisId="2"
      @closeModal="closeReimportAnalysisFileModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card min-width-scrool">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link :to="`/soil/home`" title="Voltar">
            Módulo de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis`" title="Voltar">
            Análise de Solos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link :to="`/soil/analysis/${batchId}`" title="Voltar">
            Detalhes da Análise
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Espectrofotometria
        </h5>
      </div>
      <batch-details :batch="batch" />
      <div class="card-body">
        <h4 class="font-weight-light">Dados da Análise:</h4>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-xl-2 col-lg-3 col-4">
                <div class="form-group">
                  <label>Fator Correção Padrão: </label>
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="correctionFactorDefault"
                  />
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-4">
                <div class="form-group mt-4 text-right">
                  <button
                    class="btn btn-block btn-primary"
                    title="Aplicar a Todos"
                    @click="applyToAll()"
                  >
                    Aplicar a Todos
                  </button>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-4">
                <div class="form-group mt-4 text-right">
                  <button
                    class="btn btn-block btn-yellow"
                    title="Calcular"
                    @click="calculateResults()"
                  >
                    Calcular
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-primary border-0 p-1 mb-0">
          <span class="font-weight-semibold">Observação:</span>
          Após realizar todo o preenchimento, clicar em "Salvar Dados" no final
          da página!
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div class="table-responsive mr-2">
              <table class="table table-striped table-sm font-size-sm">
                <thead>
                  <tr class="bg-primary text-white">
                    <th colspan="2" class="text-center">Amostras</th>
                    <th
                      class="text-center bg-secondary text-white"
                      :colspan="elements.length"
                    >
                      Dados Lidos
                    </th>
                    <th colspan="3" class="text-center bg-dark text-white">
                      <div class="form-group row mb-0">
                        <div class="col-lg-12">
                          <select
                            class="form-control"
                            v-model="elementSelected"
                          >
                            <option
                              v-for="element in elements"
                              :key="element.id"
                              :value="element.id"
                            >
                              {{ element.description }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="bg-primary text-center text-white p-1"
                      title="Identicador Único"
                    >
                      #
                    </th>
                    <th
                      class="bg-primary text-center text-white p-1"
                      title="Número da Amostra"
                    >
                      Nº
                    </th>
                    <th
                      class="text-center bg-secondary text-white p-1"
                      v-for="element in elements"
                      :key="element.id"
                      width="120"
                    >
                      {{ element.name }}
                    </th>
                    <th class="bg-dark text-white text-center p-1" width="120">
                      Fator Correção
                    </th>
                    <th class="bg-dark text-white text-center p-1" width="120">
                      Fator Correção Calculado
                    </th>
                    <th class="bg-dark text-white text-center p-1" width="120">
                      Cálculo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sample, index) in samples" :key="sample.sampleId">
                    <td
                      class="bg-primary-100 text-center p-2"
                      :title="`Identificador único: ${sample.sampleId}`"
                    >
                      {{ sample.sampleId }}
                    </td>
                    <td class="bg-primary-100 text-center p-2">
                      {{ index + 1 }}
                      <i
                        class="ml-1 fas fa-info-circle text-primary"
                        :title="`Cliente: ${sample.client}\nFazenda: ${sample.farm}\nTalhão: ${sample.area}\nProfundidade: ${sample.depthName}`"
                      />
                    </td>
                    <!-- Valores Lido -->
                    <td
                      class="text-center p-1 bg-secondary-100"
                      v-for="element in sample.elements"
                      :key="`${sample.sampleId}_${element.id}`"
                    >
                      <input
                        type="number"
                        class="form-control text-center width-100 m-0"
                        v-model="element.value"
                      />
                    </td>
                    <!-- Fator de Correção -->
                    <td
                      v-show="elementSelected === element.analysisElementId"
                      class="text-center p-1 bg-secondary-100"
                      v-for="element in sample.elements"
                      :key="`correction_factor_${sample.sampleId}_${element.id}`"
                    >
                      <input
                        type="number"
                        class="form-control text-center width-100 m-0"
                        v-model="element.correctionFactor"
                      />
                    </td>
                    <!-- Fator de Correção Calculado -->
                    <td
                      v-show="elementSelected === element.analysisElementId"
                      class="text-center p-1 bg-secondary-100"
                      v-for="element in sample.elements"
                      :key="`correction_factor_calc_${sample.sampleId}_${element.id}`"
                    >
                      <input
                        type="number"
                        class="form-control text-center width-100 m-0"
                        v-model="element.correctionFactorCalc"
                      />
                    </td>
                    <!-- Calculo -->
                    <td
                      v-show="elementSelected === element.analysisElementId"
                      class="text-center p-1 bg-secondary-100"
                      v-for="element in sample.elements"
                      :key="`calc_${sample.sampleId}_${element.id}`"
                    >
                      <input
                        type="number"
                        class="form-control text-center width-100 m-0"
                        v-model="element.calc"
                        readonly
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div
                v-show="elementSelected == element.id"
                v-for="(element, index) in elements"
                :key="element.id"
                width="220"
              >
                <table class="table">
                  <thead class="bg-teal text-white text-center">
                    <tr>
                      <th class="py-2-5" colspan="2">
                        {{ element.description }}
                      </th>
                    </tr>
                    <tr>
                      <th>mg L<sup>-1</sup></th>
                      <th>Leitura</th>
                    </tr>
                  </thead>
                  <tbody class="bg-teal-100">
                    <tr v-if="element.itens.length == 0">
                      <td class="text-center" colspan="2">
                        Nenhum dado informado <br />
                        Clique em adicionar para continuar.
                      </td>
                    </tr>
                    <tr
                      v-for="(item, indexItem) in element.itens"
                      :key="indexItem"
                    >
                      <td class="p-1 text-center">
                        <input
                          type="number"
                          class="form-control text-center width-100 m-0"
                          v-model="item.mgL1"
                        />
                      </td>
                      <td class="p-1 text-center">
                        <input
                          type="number"
                          class="form-control text-center width-100 m-0"
                          v-model="item.read"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between p-2 bg-teal-100">
                  <button
                    class="btn btn-warning"
                    @click="removeRowElement(index)"
                    title="Remover"
                    :disabled="element.itens.length == 0 ? true : false"
                  >
                    Remover
                  </button>
                  <button
                    class="btn btn-teal"
                    @click="addRowElement(index)"
                    title="Adicionar"
                  >
                    Adicionar
                  </button>
                </div>
                <div v-if="element.totals" class="p-1">
                  <h6>Cálculo:</h6>
                  <p>
                    <strong>Soma mg L<sup>-1</sup>: </strong>
                    {{ element.totals.sumY }}
                  </p>
                  <p>
                    <strong>Soma Leitura: </strong>{{ element.totals.sumX }}
                  </p>
                  <p>
                    <strong>Valor de A: </strong>{{ element.totals.fieldA }}
                  </p>
                  <p>
                    <strong>Valor de B: </strong>{{ element.totals.fieldB }}
                  </p>
                  <p>
                    <strong>Valor de C: </strong>{{ element.totals.fieldC }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
      <div class="card-body">
        <div class="text-right">
          <button
            title="Reimportar Novo Arquivo"
            class="btn btn-outline-dark mr-2"
            @click="reimportFileAnalysis"
          >
            <i class="fas fa-sync-alt mr-2" />Reimportar Arquivo
          </button>
          <button
            class="btn btn-outline-success"
            title="Salvar Dados"
            :disabled="disableButton"
            @click="save()"
          >
            <i class="fas fa-save mr-2" />Salvar Dados
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gaussSolver } from '@/utils/calculation'
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'
import BatchDetails from '@/components/soil/batchs/BatchDetails.vue'
import ReimportAnalysisFileModal from '@/components/soil/analysis/ReimportAnalysisFileModal.vue'

export default {
  name: 'SoilAnalysisSpectrophotometry',

  components: {
    LoadingData,
    BatchDetails,
    ReimportAnalysisFileModal,
  },

  data() {
    return {
      batchId: this.$route.params.batchId,
      disableButton: false,
      loadData: true,
      correctionFactorDefault: 1,
      elementSelected: null,
      batch: null,
      elements: null,
      samples: null,
      showReimportAnalysisFileModal: false,
    }
  },

  methods: {
    async getSamplesByBatchAndTypeAnalysis() {
      try {
        const response = await this.$store.dispatch(
          'getSamplesByBatchAndTypeAnalysis',
          {
            batchId: this.batchId,
            analysisId: 2,
          },
        )
        this.batch = response.batch
        this.elements = response.elements
        this.elementSelected = response.elements[0].id
        this.samples = response.samples
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    applyToAll() {
      this.samples.forEach((sample, index) => {
        this.samples[index].elements.forEach((element, indexEl) => {
          if (
            this.samples[index].elements[indexEl].analysisElementId ===
            this.elementSelected
          ) {
            this.samples[index].elements[indexEl].correctionFactor =
              this.correctionFactorDefault
          }
        })
      })
    },

    addRowElement(indexElement) {
      this.elements[indexElement].itens.push({
        id: null,
        analysisElementId: this.elements[indexElement].id,
        batchId: this.batchId,
        mgL1: '',
        read: '',
        calcMgL1: 0,
        calcRead: 0,
      })
    },

    removeRowElement(indexElement) {
      this.elements[indexElement].itens.pop()
    },

    calculateResults() {
      this.calculateElementsParameters()
      this.calculateCorrectionFactorCalc()
      this.calculateResultFinal()
    },

    calculateElementsParameters() {
      this.elements.forEach((el, index) => {
        if (el.itens.length > 0) {
          const numItens = el.itens.length
          let sumX = 0
          let sumX2 = 0
          let sumX3 = 0
          let sumX4 = 0
          let sumY = 0
          let sumXY = 0
          let sumX2Y = 0

          el.itens.forEach((item, itemIndex) => {
            sumX += Number(item.read)
            sumX2 += Number(item.read) * Number(item.read)
            sumX3 += Number(item.read) * Number(item.read) * Number(item.read)
            sumX4 +=
              Number(item.read) *
              Number(item.read) *
              Number(item.read) *
              Number(item.read)
            sumY += Number(item.mgL1)
            sumXY += Number(item.read) * Number(item.mgL1)
            sumX2Y += Number(item.read) * Number(item.read) * Number(item.mgL1)
          })

          const matrixA = [
            [numItens, sumX, sumX2],
            [sumX, sumX2, sumX3],
            [sumX2, sumX3, sumX4],
          ]
          const matrixB = [sumY, sumXY, sumX2Y]
          const gauss = gaussSolver(matrixA, matrixB)
          const fieldA = gauss[2]
          const fieldB = gauss[1]
          const fieldC = gauss[0]
          const totals = {
            id: el.totals !== null ? el.totals.id : null,
            analysisElementId: el.id,
            batchId: this.batchId,
            fieldA,
            fieldB,
            fieldC,
            base: {
              sumX,
              sumX2,
              sumX3,
              sumX4,
              sumY,
              sumXY,
              sumX2Y,
            },
          }
          this.elements[index].totals = totals
        }
      })
    },

    calculateCorrectionFactorCalc() {
      this.samples.forEach((sample, index) => {
        this.samples[index].elements.forEach((element, indexEl) => {
          const elementData = this.elements.filter((item) => {
            if (item.id === element.analysisElementId) {
              return item.totals
            }
            return null
          })

          if (elementData.length > 0) {
            const elementFound = elementData[0].totals
            const calc =
              element.value * element.value * elementFound.fieldA +
              elementFound.fieldB * element.value +
              elementFound.fieldC

            this.samples[index].elements[indexEl].correctionFactorCalc =
              calc.toFixed(2)
          }
        })
      })
    },

    calculateResultFinal() {
      this.samples.forEach((sample, index) => {
        this.samples[index].elements.forEach((element, indexEl) => {
          this.samples[index].elements[indexEl].calc = (
            element.correctionFactor * element.correctionFactorCalc
          ).toFixed(2)
        })
      })
    },

    async save() {
      try {
        this.disableButton = true
        this.calculateResults()
        const response = await this.$store.dispatch(
          'saveSamplesFromSpectrophotometryAnalysis',
          {
            batchId: this.batchId,
            samples: this.samples,
            elements: this.elements,
          },
        )
        this.disableButton = false
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    reimportFileAnalysis() {
      this.showReimportAnalysisFileModal = true
    },

    closeReimportAnalysisFileModal(excluded) {
      this.showReimportAnalysisFileModal = false
      if (excluded) {
        this.$router.push(PATHS.SOIL_ANALYSIS_NEW)
      }
    },
  },

  mounted() {
    this.getSamplesByBatchAndTypeAnalysis()
  },
}
</script>

<style scoped>
.width-100 {
  width: 100px;
}

.py-2-5 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.min-width-scrool {
  min-width: 1000px;
}
</style>
