<template>
  <div class="row mt-2">
    <div v-if="getModuleUserByCode('3.01')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Profundidades de Medida"
        title="Gerenciar Profundidades de Medida"
        route="depths"
        color="purple"
        icon="fas fa-ruler-vertical fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('3.02')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Tipos de Análises"
        title="Tipos de Análises"
        route="types-of-analysis"
        color="info"
        icon="fas fa-atom fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('3.03')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Remessas"
        title="Gerenciar Remessas"
        route="shipments"
        color="teal"
        icon="fas fa-dolly fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('3.04')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Lotes"
        title="Gerenciar Lotes"
        route="batchs"
        color="pink"
        icon="fas fa-box-open fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('3.05')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Análise de Solo"
        title="Importar arquivos preenchidos com as análises"
        route="analysis"
        color="warning"
        icon="fas fa-flask fa-3x"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardLink from '@/components/commons/CardLink.vue'

export default {
  name: 'Functionalities',

  components: {
    CardLink,
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },
}
</script>
