<template>
  <div class="d-flex flex-row align-items-center">
    <img
      src="@/assets/img/modules/experimental-minimalista.png"
      alt="Solos"
      width="60"
      class="mr-3"
    />
    <h1 class="ml-3 my-2 font-weight-light">Módulo do Solos</h1>
  </div>
</template>

<script>
export default {
  name: 'HeaderPage',
}
</script>
