<template>
  <div class="content">
    <create-edit-depth-modal
      v-if="showCreateEditDepthModal"
      :depthId="depthIdSelected"
      @closeModal="closedCreateEditDepthModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar"> Módulo de Solos </router-link>
          <i class="fas fa-angle-right ml-2" />
          Profundidade das Amostras
        </h5>
        <div v-if="getModuleUserByCode('3.01.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Medição de Profundidade"
            @click="createEditDepth()"
          >
            Nova Medição de Profundidade
          </button>
        </div>
      </div>
      <list-depths :depths="depths" @createEditDepth="createEditDepth" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListDepths from '@/components/soil/depths/ListDepths.vue'
import CreateEditDepthModal from '@/components/soil/depths/CreateEditDepthModal.vue'

export default {
  name: 'SoilDepths',

  components: {
    LoadingData,
    CreateEditDepthModal,
    ListDepths,
  },

  data() {
    return {
      loadData: true,
      showCreateEditDepthModal: false,
      depths: [],
      depthIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getDepths() {
      try {
        this.depths = await this.$store.dispatch('getDepths')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditDepth(depthId) {
      this.depthIdSelected = depthId || null
      this.showCreateEditDepthModal = true
    },

    closedCreateEditDepthModal(loadListDepths) {
      if (loadListDepths) {
        this.loadData = true
        this.getDepths()
      }
      this.showCreateEditDepthModal = false
    },
  },

  mounted() {
    this.getDepths()
  },
}
</script>
